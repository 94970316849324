/* The nav is a modal which is outside of the MFE container */
@at-root {
  #catalogNavToaster {
    color: color('primary-dark', $brand: $brand);
    z-index: $small-nav-z-index;

    .modal-heading {
      background-color: transparent;
      padding: 0;
    }
    .modal-block {
      height: 100%;
      width:calc(100% - 60px);
      max-width: 400px;
    }
    .modal-close-button {
      height: 75px;
      width: 75px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      &:focus {
        outline: none;
        .modal-close-icon {
          @include focus-indicator(-5px);
        }
      }
    }
    .modal-close-icon::before {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      padding: 0;
    }
  }

}