@import 'node_modules/anf-core/dist/1892/1892-framework';
@import 'src/styles/fragments/_variables.scss';
@import 'src/styles/fragments/_functions.scss';

$primaryNavHeight: $desktop-nav-height;

$brandSpecificStyles: (
  'anf': (
    category-link-underline-height: 2px,
    category-list-item-padding-right: 25px,
    logo-font-size: 1.87rem,
    category-link-font-stretch: 100%,
    category-link-letter-spacing: 0.6px,
  ),
  'hol': (
    category-link-underline-height: 3px,
    category-list-item-padding-right: 40px,
    logo-font-size: 1.8rem,
    category-link-font-stretch: 75%,
    category-link-letter-spacing: 0,
    category-link-text-transform: capitalize,
  ),
  'kids': (
    category-link-underline-height: 2px,
    category-list-item-padding-right: 25px,
    logo-font-size: 1.8rem,
    category-link-font-stretch: 100%,
    category-link-letter-spacing: 0.6px,
  ),
  'gh': (
    category-link-underline-height: 3px,
    category-list-item-padding-right: 40px,
    logo-font-size: 1.8rem,
  ),
  'st': (
    category-link-underline-height: 3px,
    category-list-item-padding-right: 40px,
    logo-font-size: 1.8rem,
  )
);

$theme: map-get($brandSpecificStyles, $brand);

.large-screen-nav-container {
  $logo-font-size: map-get($theme, logo-font-size);

  display: none;
  height: $primaryNavHeight;

  @include media-query(1025) {
    display: flex;
  }

  .logo-link {
    align-items: center;
    display: flex;
    margin-left: 20px;
    margin-right: 25px;
    text-decoration: none;
    &:focus {
      outline-offset: 0;
    }
  }

  .logo {
    color: color('primary-dark');
    font-size: $logo-font-size;
    padding-left: 5px;
    padding-right: 25px;
  }

  .custom-logo {
    height: auto;
    max-width: 148px;
    width: 100%;
  }
}

.large-screen-nav-bar {
  $category-list-item-padding-right: map-get($theme, category-list-item-padding-right);
  $category-link-underline-background-color: map-get($theme, category-link-underline-background-color);
  $category-link-underline-height: map-get($theme, category-link-underline-height);

  position: relative;

  > ul {
    display: flex;
    flex-wrap: wrap;
    height: $primaryNavHeight;
    list-style: none;
    overflow: hidden;
  }

  /*
    You're going to see some legacy class names below ("rs-" something or "nav-" something).
    This is to help content authors' markup (which might still use these class names) 
    still look OK in this new MFE component.  We can work with them to convert their
    classes to our new naming/structure, but these styles are here to help in the meantime!
  */
  // BEGIN STYLES FOR LEGACY CLASS NAMES

  .nav-bar-category-list-item {
    height: $primaryNavHeight;
    line-height: $primaryNavHeight;
    padding-right: $category-list-item-padding-right;
    white-space: nowrap;
    position: static;
    visibility: visible;

    @include media-query(1025) {
      .nav-accordion-items--secondary {
        display: flex;
      }
    }

    .rs-nav-item--minor a.rs-nav-link--minor {
      text-decoration: none;
    }

    &.nav-bar-item-hidden {
      visibility: hidden;

      .aem-content.aem-mfe {
        visibility: hidden;
        transition: none;
      }
    }
  }


@include media-query (1025) {
  $transitionDelay: 0s;
  $transitionSpeed: 0s;
    .rs-nav__cat-wrap .rs-nav__cat-column.regular .rs-cat-nav__header {
      display: block;
    }

    .rs-nav__cat-column-container .clearance > ul .rs-nav-link--minor {
      $clearance-text-color: color(brand-style(('discount', 'discount', 'discount-light'), $brand), $brand: $brand); 

      color: $clearance-text-color;
      display: inline-block;

      span.font-icon {
        float: right;
      }

      &:hover,
      &:focus {
        color: $clearance-text-color !important;// temporary

        &:after {
          background-color: $clearance-text-color !important;// temporary
        }
      }
    }

  .rs-cat-nav__header {
    $link-minor-hover-color: color(brand-style(('primary-dark', 'primary-dark', 'navy-900'), $brand), $brand: $brand);
    position: relative;

    a {
      display: inline-block;
      position: relative;

      &:focus {
        outline: none !important;
      }

      &:after {
        bottom: px(-3);
        content: '';
        height: px(2);
        left: 0;
        position: absolute;
        transition: all .25s linear;
        width: 100%;
      }

      &:hover:after,
      &:focus:after {
        background: $link-minor-hover-color;
        display: inline-block;
      }

      h2 {
        margin-bottom: 0;

        &:focus {
          outline: none !important;
        }
      }
    }
  }

  .rs-nav__cat-container {
    display: flex;
  }

  .rs-nav__cat-column-container {
    display: flex;
    flex-direction: column;
    min-width: 260px;

    &.thin-nav-column { // When we want to give a container a less-wide appearance (such as with columns containing broken-out L3 categories)
      min-width: 200px;
    }

    &.l3-formatting { // When we want to give a container special formatting when it exists alongside L3 category columns (but not necessarily making it "thin")
      background-color: color(brand-style(('neutral-050', 'neutral-050', 'neutral-100'), $brand), $brand: $brand);
      padding-left: brand-style(('35px', '35px', '53px'), $brand);
      white-space: normal;
    }
  }

  .rs-nav__cat-column {
    min-width: 260px;
    overflow-y: hidden; // IE11 layout fix for when underlines appear under hovered-on links inside
    position: relative; // Same!

    &.thin-nav-column {// reduce the width when GH or expanded L3 is on.  make permenant when GH and expanded L3 is set to always ON
      min-width: 200px;
      white-space: normal;
    }

    &.regular,
    &.special {
      margin: 42px 0 60px 60px;
      margin-left: 0;
    }

    &:first-child {
      margin-left: 60px;
    }

    &.featured {
      margin: 42px 20px 0 0;
    }

    &.clearance {
      margin: 0 20px 60px 0;
    }
  }
}

/* Adding this because the ".rs-nav-item--minor" that appears below this eSpot used to have some
  spacing/padding above its text.  Due to the above styles, it won't anymore!  This should keep
  content that might appear in this espot to still have the same spacing from the ".rs-nav-item--minor"
  below it. */
.rs-nav-item--featured-cat-espot .genericESpot:not(:empty) {// stylelint-disable-line selector-class-pattern
  margin-bottom: 19px;
}

  // END STYLES FOR LEGACY CLASS NAMES

  .nav-bar-category-link-heading {
    color: color('primary-dark');
    display: inline-block;
    font-size: rem(15);
    font-weight: bold;
    line-height: $primaryNavHeight;
    position: relative; 

    font-stretch: map-get($theme, category-link-font-stretch);
    letter-spacing: map-get($theme, category-link-letter-spacing);
    text-transform: map-get($theme, category-link-text-transform);


    &::after {
      background-color: color('primary-dark');
      bottom: 0;
      content: '';
      height: $category-link-underline-height;
      left: 0;
      opacity: 0;
      position: absolute;
      transition-duration: .15s;
      transition-property: opacity;
      width: 100%;
    }

    &--active::after {
      // Use of !important because we never ever want to lose ownership of this element appearing on hover
      opacity: 1 !important; // sass-lint:disable-line no-important
    }
  }

  .nav-bar-category-link {
    color: color('primary-dark');
    display: inline-block;
    text-decoration: none;
  }

  .nav-bar-category-toggle-button {
    background: none;
    border: none;
    margin-left: 2px;
    padding: 2px;

    &:focus {
      @include focus-indicator();
    }

    &:not(:focus) {
      @include screen-reader-text();
    }
  }

  .flyout-panel-section {
    display: none;
    left: 0px;
    top: $primaryNavHeight;
    position: absolute;

    @include media-query(1025) {
      background: color('primary-light');
    }
    // See the comment about legacy classnames earlier in this file... here's another block!
    // BEGIN STYLES FOR LEGACY CLASS NAMES

    .rs-cat-nav__header {
      @include media-query(1025) {
        line-height: 1.4;
        margin-bottom: 22px;
      }

      a {
        text-decoration: none;
      }
    }

    @include media-query(1025) {
      .nav-accordion-container--level2 .rs-nav-item--minor {
        line-height: 1.4;
        margin-bottom: 13px;
      }

      .rs-nav-item--minor > .rs-nav-link--minor {

       &:after {
          bottom: -3px;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          transition: all .25s linear;
          width: 100%;
        }

        &:hover:after,
        &:focus:after {
          background: $category-link-underline-background-color;
        }
      }
    }
    // END STYLES FOR LEGACY CLASS NAMES
  }

  .nav-bar-category-toggle-button[aria-expanded="true"] + .flyout-panel-section {
    display: block;
  }

  .full-width .flyout-panel-section {
    left: 0;
    right: 0;

    .nav-flyout-list {
      display: flex;
      flex-direction: column;
      & > li {
        min-width: 180px;
      }
      // only split categories into columns if we know they'll fit
      @include media-query(1200) {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(8, auto);
      }
    }
  }

  .full-width .nav-flyout-panel {
    justify-content: space-between;
    // don't let espot content squish our actal nav
    flex-shrink: 0;
    & > div + div {
      border-left: 1px solid color('tertiary-light');
      padding-left: 40px;
      @if $brand == hol {
        // no dividing lines for hollister's different category structure
        border-left: none;
        padding-left: 0;
      }
    }
  }

}