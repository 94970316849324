@import 'node_modules/anf-core/dist/1892/1892-framework';

$brandSpecificStyles: (
  'anf': (
    category-link-font-size: 14,
    category-link-letter-spacing: 0,
    category-link-underline-color: #253746,
    clearance-category-link-color: #9e3533,
    l3-experience-column-vertical-container-background-color: #f6f6f6,
    l3-experience-column-vertical-container-padding-left: 35px,
  ),
  'hol': (
    category-link-font-size: 15,
    category-link-letter-spacing: 0.8,
    category-link-underline-color: #292a33,
    clearance-category-link-color: #c00,
    l3-experience-column-vertical-container-background-color: #f5f4f2,
    l3-experience-column-vertical-container-padding-left: 53px,
  ),
  'kids': (
    category-link-font-size: 14,
    category-link-letter-spacing: 0,
    category-link-underline-color: #20222b,
    clearance-category-link-color: #9e3533,
    l3-experience-column-vertical-container-background-color: #f6f6f6,
    l3-experience-column-vertical-container-padding-left: 35px,
  ),
  'gh': (
    category-link-font-size: 15,
    category-link-letter-spacing: 0,
    category-link-underline-color: #2e2f2f,
    clearance-category-link-color: #c00,
  ),
  'st': (
    category-link-font-size: 15,
    category-link-letter-spacing: 0,
    category-link-underline-color: #131313,
    clearance-category-link-color: #c00,
  )
);

.nav-flyout-container {
  $theme: map-get($brandSpecificStyles, $brand);

  $category-link-font-size: map-get($theme, category-link-font-size);
  $category-link-letter-spacing: map-get($theme, category-link-letter-spacing);
  $category-link-underline-color: map-get($theme, category-link-underline-color);
  $clearance-category-link-color: map-get($theme, clearance-category-link-color);
  $l3-experience-column-vertical-container-background-color: map-get($theme, l3-experience-column-vertical-container-background-color);
  $l3-experience-column-vertical-container-padding-left: map-get($theme, l3-experience-column-vertical-container-padding-left);

  background-color: color('primary-light');
  display: flex;

  .nav-flyout-panel {
    display: flex;
    max-width: 90vw;
    overflow: auto;
  }

  ul {
    list-style: none;
  }

  .nav-flyout-column {
    margin: 42px 0 60px 0;
    min-width: 260px;

    &:first-child {
      margin-left: 60px;
    }

    &.regular {
      flex-shrink: 0;
    }

    &.featured {
      margin: 42px 20px 0 0;

      &:first-child {
        margin-left: 60px;
        margin-bottom: 60px;
      }
    }

    /*
      Negative top margin here for clearance items because they'll always appear below regular items
      in the same column. It will counteract the bottom margin of the regular items ensuring that,
      whether or not we have clearance items or not, we'll have a consistent bottom margin of 60px for the column!
    */
    &.clearance {
      margin: -47px 0 60px 60px;
      .category-link {
        color: $clearance-category-link-color;
      }
    }

    &.thin-column {
      min-width: 200px;

      ~ .nav-column-vertical-container {
        background-color: $l3-experience-column-vertical-container-background-color;
        padding-left: $l3-experience-column-vertical-container-padding-left;
      }
    }
  }

  .nav-column-vertical-container {
    flex-direction: column;
    flex-shrink: 0;
  }

  .nav-category-column-header {
    margin-bottom: 22px;
    line-height: 1;
  }

  .category-list-item {
    line-height: 1.5;
    margin: 0 0 13px 0;
    padding-right: 40px;
  }

  .full-width .nav-flyout-container {
    // make sure this stays contained
    overflow-x: hidden;
  }

  .full-width .category-list-item {
    @if $brand == hol {
      //same padding on either side of columns for hollister
      padding-right: 60px;
    }
  }

  .category-link {
    display: inline-block;
    font-size: rem($category-link-font-size);
    letter-spacing: $category-link-letter-spacing;
    line-height: 22px;
    position: relative;
    text-decoration: none;

    &:after {
      bottom: -3px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      transition: all .25s linear;
      width: 100%;
    }

    &:hover:after, &:focus:after {
      background: $category-link-underline-color;
    } 
  }

  .nav-category-column-header .category-link {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}
