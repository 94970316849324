.siteMap {
  margin: 0;
  padding: 0;

  .sitemapSection {
    display: block;
    margin: 0 auto;
    max-width: 1568px;
    text-transform: capitalize;
    padding: 20px;
  }

  a {
    text-decoration: none;
  }

  .allSiteMapList {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 -15px;
  }

  .level1Category {
    padding: 30px 15px 0;
    flex-basis: 100%;

    @media screen and (min-width: 700px) {
      flex-basis: 50%;
      max-width: 50%;
    }

    @media screen and (min-width: 1025px) {
      flex-basis: 33.333333%;
      max-width: 33.333333%;
    }

    @media screen and (min-width: 1200px) {
      flex-basis: 25%;
      max-width: 25%;
    }
  }

  .level2CategoryWrapper {
    padding-top: 15px;
    list-style: none;
  }

  .level2Category {
    padding: 10px 0;
    font-size: 1.5rem;
  }

  .levelCategoryWrapper {
    list-style: none;
  }

  .levelCategory {
    margin-left: 20px;
    padding: 10px 0;
    font-size: 1.5rem;
  }

  // Puts the Disclosure button next to the text. Validating with DS team
  .disclosure .disclosure-label {
    margin-right: 0;
  }
}
